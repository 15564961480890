import Empty from '@/components/Empty.vue';
import Home from '@/views/Home.vue';
import RouterGroup from '@/components/RouterGroup.vue';

export const adminMenus = [
  {
    name: 'Home',
    path: '/admin',
    component: Home,
    meta: {
      icon: 'home',
      title: '控制台首页',
    },
  },
  {
    path: '/admin/user',
    component: RouterGroup,
    meta: {
      icon: 'idcard',
      title: '用户管理',
    },
    children: [
      {
        path: '/admin/user/:id(\\d+)',
        name: 'UserDetail',
        component: () => import(/* webpackChunkName: 'user' */'@/views/user/UserDetail.vue'),
        props(route) {
          const props = { ...route.params };
          props.id = +props.id;
          return props;
        },
        meta: {
          key: '/admin/user',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/user/add',
        name: 'UserAdd',
        component: () => import(/* webpackChunkName: 'user' */ '@/views/user/UserAdd.vue'),
        meta: {
          icon: 'user-add',
          title: '添加用户',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/user',
        name: 'UserList',
        component: () => import(/* webpackChunkName: 'user' */ '@/views/user/UserList.vue'),
        meta: {
          icon: 'profile',
          title: '用户列表',
          roles: ['admin'],
        },
      },
    ],
  },
  {
    path: '/admin/official',
    component: RouterGroup,
    meta: {
      icon: 'qq',
      title: '官号管理',
    },
    children: [
      {
        path: '/admin/official/:id(\\d+)',
        name: 'OfficialDetail',
        component: Empty,
        meta: {
          key: '/admin/official',
        },
      },
      {
        path: '/admin/official/add',
        name: 'OfficialAdd',
        component: () => import(/* webpackChunkName: 'official' */'@/views/official/OfficialAdd.vue'),
        meta: {
          icon: 'user-add',
          title: '添加官号',
          roles: ['admin', 'senioroperator'],
        },
      },
      {
        path: '/admin/official/whitelist',
        name: 'OfficialWhitelist',
        component: () => import(/* webpackChunkName: 'official' */'@/views/official/Whitelist.vue'),
        meta: {
          icon: 'solution',
          title: '白名单管理',
          roles: ['admin', 'senioroperator'],
        },
      },
      {
        path: '/admin/official',
        name: 'OfficialList',
        component: () => import(/* webpackChunkName: 'official' */'@/views/official/OfficialList.vue'),
        meta: {
          icon: 'bars',
          title: '官号列表',
          roles: ['admin', 'senioroperator'],
        },
      },
    ],
  },
  {
    path: '/admin/audit',
    name: 'AdminAudit',
    component: () => import(/* webpackChunkName: 'audit' */'@/views/audit/AuditList.vue'),
    meta: {
      icon: 'audit',
      title: '审核管理',
      roles: ['admin', 'senioroperator'],
    },
  },
  {
    path: '/admin/topic',
    component: RouterGroup,
    meta: {
      icon: 'number',
      title: '话题管理',
    },
    children: [
      {
        path: '/admin/topic',
        name: 'TopicList',
        component: () => import(/* webpackChunkName: 'topic' */'@/views/topic/TopicList.vue'),
        meta: {
          icon: 'tags',
          title: '话题列表',
          roles: ['admin', 'senioroperator', 'operator'],
        },
      },
      {
        path: '/admin/topic/add',
        name: 'TopicAdd',
        component: () => import(/* webpackChunkName: 'topic' */'@/views/topic/TopicAdd.vue'),
        meta: {
          icon: 'tag',
          title: '新增话题',
          roles: ['admin', 'senioroperator', 'operator'],
        },
      },
    ],
  },
  {
    path: '/admin/moment',
    component: RouterGroup,
    meta: {
      icon: 'shake',
      title: '心情管理',
    },
    children: [
      {
        path: '/admin/moment',
        name: 'MomentList',
        component: () => import(/* webpackChunkName: 'moment' */'@/views/moment/MomentList.vue'),
        meta: {
          icon: 'bars',
          title: '心情列表',
          roles: ['admin', 'senioroperator', 'operator'],
        },
      },
      {
        path: '/admin/moment/add',
        name: 'MomentAdd',
        component: () => import(/* webpackChunkName: 'moment' */'@/views/moment/MomentAdd.vue'),
        meta: {
          icon: 'file-add',
          title: '发表心情',
          roles: ['admin', 'senioroperator', 'operator'],
        },
      },
      {
        path: '/admin/moment/cron',
        component: () => import(/* webpackChunkName: 'moment' */'@/views/moment/MomentCron.vue'),
        name: 'MomentCron',
        meta: {
          icon: 'dashboard',
          title: '定时发布',
          roles: ['admin', 'senioroperator', 'operator'],
        },
      },
      {
        path: '/admin/moment/:mid(\\d+)',
        component: () => import(/* webpackChunkName: 'moment' */'@/views/moment/MomentShow.vue'),
        meta: {
          roles: ['admin', 'senioroperator', 'operator'],
        },
        props: ({ params }) => ({ mid: Number.parseInt(params.mid, 10) || 0 }),
      },
    ],
  },
  {
    name: '头衔管理',
    path: '/admin/title',
    component: () => import(/* webpackChunkName: 'admin-title' */'@/views/title/TitleList.vue'),
    meta: {
      icon: 'file-add',
      title: '头衔管理',
      roles: ['admin'],
    },
  },
  {
    name: 'LogList',
    path: '/admin/log',
    component: () => import(/* webpackChunkName: 'admin-log' */'@/views/log/LogList.vue'),
    meta: {
      icon: 'security-scan',
      title: '日志查看',
      roles: ['admin'],
    },
  },
  {
    path: '/admin/data',
    component: RouterGroup,
    meta: {
      icon: 'database',
      title: '数据管理',
      roles: ['admin'],
    },
    children: [
      {
        path: '/admin/data/user',
        component: () => import(/* webpackChunkName: 'data' */'@/views/data/UserData.vue'),
        name: 'UserData',
        meta: {
          icon: 'delete',
          title: '批量删除',
          roles: ['admin'],
        },
      },
    ],
  },
];
