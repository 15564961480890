<template>
  <div id="app">
    <APageHeader :title="defaultTitle" />
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Empty',
  props: {
    title: { type: String, default: '' },
  },
  computed: {
    defaultTitle() {
      return this.title || this.$route?.meta?.title || '空页面';
    },
  },
};
</script>
