import Callback from './Callback.vue';
import Register from './Register.vue';
import Auth from '../layout/Auth.vue';
import ChangeEnv from './ChangeEnv.vue';

export default {
  Callback,
  ChangeEnv,
  Register,
  Auth,
};
