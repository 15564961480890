import axios from 'axios';
import { getToken } from '@/api/auth.js';
import { getEnv, getEnvByKey } from '@/api';

export function HttpError(code, message) {
  Error.call(this, message);
  this.message = message;
  this.code = code;
}

HttpError.prototype = Object.create(Error.prototype);
HttpError.prototype.constructor = HttpError;

axios.interceptors.request.use(
  (config) => {
    let { url } = config;
    if (getEnv()) {
      const env = getEnvByKey(getEnv());
      console.log(env);
      if (env && env.version && env.version !== '' && !/\/api\/v[\d]/i.test(url)) {
        url = url.replace('/api/', `/api/${env.version}/`);
      }
      console.debug('versioned url: ', url);
    }
    const localConfig = { ...config, url };
    const token = getToken();
    if (token && token !== '') {
      localConfig.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }
    return localConfig;
  },
  err => Promise.reject(err),
);

axios.interceptors.response.use(
  (config) => {
    if (Array.isArray(config.data)) {
      return config.data;
    }
    const { error, message, data } = config.data;
    if (error) {
      return Promise.reject(new HttpError(error, message));
    }
    return data;
  },
  (err) => {
    if (err.response) {
      const { error, message } = err.response.data;
      if (error && message && error !== 0) {
        return Promise.reject(new HttpError(error, message));
      }
      return Promise.reject(new HttpError(err.response.status, err.response.data));
    }
    return Promise.reject(new HttpError(500, err.message));
  },
);

export default axios;
