import request from '@/api/request.js';

export const createOfficial = async data => request('/api/officials', {
  method: 'POST',
  data,
});

export const forbidOfficial = async roleId => request(`/api/officials/${roleId}`, {
  method: 'PATCH',
  data: {
    action: 'forbid',
  },
});

export const getOfficial = async roleId => request(`/api/officials/${roleId}`);

export const queryOfficial = async keyword => request('/api/officials/query', {
  method: 'GET',
  params: { q: keyword },
});

export const updateOfficial = async (id, data) => request(`/api/officials/${id}`, {
  method: 'PUT',
  data,
});

export const checkOfficialName = async name => request('/api/officials/checkname', {
  method: 'GET',
  params: { name },
});

export const getOfficials = async p => request('/api/officials', {
  method: 'GET',
  params: { p },
});

export const getWhitelists = async () => request('/api/whitelists');
export const addWhitelist = async roleId => request('/api/whitelists', {
  method: 'POST',
  data: { roleId },
});
export const delWhitelist = async roleId => request(`/api/whitelists/${roleId}`, {
  method: 'DELETE',
});

export default {
  addWhitelist,
  checkOfficialName,
  createOfficial,
  delWhitelist,
  forbidOfficial,
  getOfficials,
  getOfficial,
  getWhitelists,
  queryOfficial,
  updateOfficial,
};
