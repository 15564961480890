import { base64Decode, base64Encode } from '@/helper.js';
import request from '@/api/request.js';
import { USER_APPROVE, USER_DEREGISTER } from '@/api/index.js';

const LOCAL_KEY = 'gzone_info';

export function getUserData() {
  const encrypedData = localStorage.getItem(LOCAL_KEY);
  if (!encrypedData) {
    return null;
  }
  const data = JSON.parse(base64Decode(encrypedData));
  const { token, expire, user } = data;
  if (!token || !expire || !user) {
    localStorage.removeItem(LOCAL_KEY);
    return null;
  }
  const now = ~~(new Date().getTime() / 1000);
  if (expire - now <= 180) {
    localStorage.removeItem(LOCAL_KEY);
    return null;
  }
  return data;
}

export function isLogin() {
  return getUserData() !== null;
}

export function getUser() {
  const userData = getUserData();
  if (!userData) {
    return null;
  }
  return userData.user;
}

export function getToken() {
  const userData = getUserData();
  if (!userData) {
    return '';
  }
  return userData.token;
}

export function getEnv() {
  const userData = getUserData();
  if (!userData) {
    return '';
  }
  return userData.env;
}

export function getGroup() {
  const { user } = getUserData();
  if (!user) {
    return '';
  }
  return user.role.name;
}

export function loginUser(user, token, expire) {
  const { id, name, avatar, role, officials, status, env } = user;
  const savedUser = {
    user: {
      id, name, avatar, role, officials, status,
    },
    token,
    expire,
    env,
  };
  localStorage.setItem(LOCAL_KEY, base64Encode(savedUser));
}

export const logoutUser = () => {
  localStorage.removeItem(LOCAL_KEY);
};

export const oAuthLogin = (code, state) => request('/api/login', {
  method: 'POST',
  data: { code, state },
});

export const getLoginUri = async () => request('/api/login-url');
export const bindAccount = async postData => request('/api/register', {
  method: 'POST',
  data: postData,
});

export const getUsers = async p => request('/api/users', {
  method: 'GET',
  params: { p },
});

export const getUserById = async id => request(`/api/users/${id}`);
export const postUser = async data => request('/api/users', {
  method: 'POST',
  data,
});
export const putUser = async data => request(`/api/users/${data.id}`, {
  method: 'PUT',
  data,
});

export const approveUser = async id => request(`/api/users/${id}`, {
  method: 'PATCH',
  data: {
    action: USER_APPROVE,
  },
});

export const deregisterUser = async id => request(`/api/users/${id}`, {
  method: 'PATCH',
  data: {
    action: USER_DEREGISTER,
  },
});

export const deleteUser = async id => request(`/api/users/${id}`, {
  method: 'DELETE',
  data: {
    id,
  },
});

export const getRoles = async () => request('/api/roles');

export default {
  approveUser,
  bindAccount,
  deleteUser,
  deregisterUser,
  getLoginUri,
  getRoles,
  getToken,
  getUserById,
  getUsers,
  getUserData,
  getUser,
  isLogin,
  loginUser,
  logoutUser,
  oAuthLogin,
  postUser,
  putUser,
};
