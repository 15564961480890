import request from '@/api/request.js';

export const checkTopicTitle = async title => request('/api/topics/checktitle', {
  method: 'GET',
  params: { title },
});

export const createTopic = async data => request('/api/topics', {
  method: 'POST',
  data,
});

export const queryTopics = async q => request('/api/topics/query', {
  method: 'GET',
  params: { q },
});

export const updateTopic = async (id, data) => request(`/api/topics/${id}`, {
  method: 'PUT',
  data,
});

export const getTopics = async () => request('/api/topics');

export const delTopic = async topicId => request(`/api/topics/${topicId}`, {
  method: 'DELETE',
});

export const toggleTopMoment = async (topicId, momentId) => request(`/api/topics/${topicId}/set-top`, {
  method: 'POST',
  data: { momentId },
});

export default {
  checkTopicTitle,
  createTopic,
  delTopic,
  getTopics,
  queryTopics,
  updateTopic,
  toggleTopMoment,
};
