import Vue from 'vue';
import VueRouter from 'vue-router';
import DefaultLayout from '@/views/layout/Default.vue';
import Empty from '@/components/Empty.vue';
import AuthComponents from '@/views/auth';
import { adminMenus } from './admin';
import { getUser, isLogin, getEnv } from '@/api';

Vue.use(VueRouter);

const { Auth, Callback, Register, ChangeEnv } = AuthComponents;

export const routes = [
  {
    path: '/',
    name: 'GZoneHome',
    redirect: '/admin',
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: 'error' */'@/views/Error.vue'),
    props: (route) => {
      const { code, msg } = route.query;
      return {
        code,
        msg,
      };
    },
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: '/auth/qq',
        name: 'QQLogin',
        component: Callback,
        props: (route) => {
          const { code, state } = route.query;
          return {
            code,
            state,
          };
        },
      },
      {
        path: '/auth/register',
        name: 'AccountBind',
        component: Register,
        props: (route) => {
          const { token } = route.query;
          return {
            token,
          };
        },
      },
      {
        path: '/auth/envs',
        name: 'ChangeEnv',
        component: ChangeEnv,
      },
    ],
  },
  {
    path: '/admin',
    component: DefaultLayout,
    children: adminMenus,
  },
  {
    path: '*',
    component: Empty,
    name: 'NotFound',
  },
];

const checkAuth = (to, from, next) => {
  if (
    ['Login', 'QQLogin', 'AccountBind', 'Error'].includes(to.name)
  ) {
    next();
  } else {
    if (isLogin()) {
      const roles = to.meta?.roles || [];
      const role = getUser().role.name || '';
      if (roles.length && roles.every(item => item.toLowerCase() !== role.toLowerCase())) {
        next({
          name: 'Error',
          query: {
            code: 403,
            msg: '您无权进行此项操作',
          },
        });
      } else if (getEnv() === '' && to.path !== '/auth/envs') {
        next({ path: '/auth/envs' });
      } else {
        next();
      }
    } else {
      next(false);
      window.location.href = '/passport/login';
    }
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(checkAuth);

export default router;
