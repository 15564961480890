<template>
  <div id="app">
    <RouterView class="auth-wrap" />
  </div>
</template>
<script>
export default {
  name: 'Auth',
};
</script>
<style>
.auth-wrap {
  margin: 10rem auto;
  max-width: 50rem;
}
</style>
