<template>
  <AConfigProvider :locale="locale">
    <RouterView />
  </AConfigProvider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      error: null,
    };
  },
  created() {
    this.$bus.$on('gzoneError', this.handleError);
  },
  beforeDestroy() {
    this.$bus.$off('gzoneError');
  },
  methods: {
    handleError(err) {
      const { code, message } = err;
      if (code && code === 401) {
        window.location.href = '/passport/login';
      } else {
        this.$router.push({
          name: 'Error',
          query: { code, msg: message },
        });
      }
    },
  },
};
</script>
<style>
#app {
  height: 100%;
}
#app .ant-table + .ant-pagination {
  margin: 1rem 2rem;
}

#app .ant-form-explain {
  margin-bottom: 1rem;
}
.topic-pic-pop {
  max-width: 80%;
  max-height: 80%;
}
.pic-thumbnail {
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: 1px solid #bdbdbd;
  background: #bdbdbd;
}
.pic-thumbnail + .pic-thumbnail {
  margin-left: 0.4rem;
}
.pic-preview {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.margin-wrap {
  margin: 1rem;
}
</style>
