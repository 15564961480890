import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import 'ant-design-vue/dist/antd.css';
import { getGroup } from './api/auth';

Vue.config.productionTip = false;

Vue.use(Antd);

Vue.config.errorHandler = function (err, vm, info) {
  console.log('global handle', err);
  if (err.code && err.code === 401) {
    // router.replace('/passport/login').then(() => {}, () => {});
    window.location.href = '/passport/login';
  } else {
    vm.$error({
      title: '出错了',
      content: err.message,
    });
    console.error({
      err, vm, info,
    });
  }
};

Vue.prototype.$bus = new Vue();

Vue.mixin({
  computed: {
    isRA() {
      return getGroup() === 'Admin';
    },
    isRSO() {
      return getGroup() === 'SeniorOperator';
    },
    isRO() {
      return getGroup() === 'Operator';
    },
  },
});

new Vue({
  router,
  render(h) {
    return h(App);
  },
}).$mount('#app');

