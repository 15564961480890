<template>
  <div class="home">
    <APageHeader title="控制台首页" />
  </div>
</template>
<script>
export default {
  name: 'Home',
};
</script>
<style>
.env-select {
  margin: 1rem 3rem;
}
.env-select .env-radio {
  display:block;
  height: 3rem;
  line-height: 3rem;
}
</style>
