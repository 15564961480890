<script>
import { oAuthLogin, getEnv, loginUser } from '@/api';

export default {
  name: 'Callback',
  props: {
    code: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      default: '',
    },
  },
  async created() {
    try {
      const { expire, token, user, openid } = await oAuthLogin(this.code, this.state);
      // console.log(expire, token, user, openid);
      if (!user) {
        await this.$router.replace({
          name: 'AccountBind',
          query: {
            token: openid,
          },
        });
      } else {
        if (user.status !== 2) {
          await this.$router.replace({
            name: 'Error',
            query: {
              code: 40010,
            },
          });
        } else {
          loginUser(user, token, expire);
          if (getEnv() === '') {
            await this.$router.replace({ path: '/auth/envs' });
          } else {
            await this.$router.replace({ name: 'Home' });
          }
        }
      }
    } catch (err) {
      await this.$router.replace({
        name: 'Error',
        query: {
          code: 'error',
          msg: err.message,
        },
      });
    }
  },
  render: h => h(),
};
</script>
