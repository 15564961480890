import request from './request';

export const createMoment = async data => request('/api/moments', {
  method: 'POST',
  data,
});

export const deleteComment = async (mid, cid, oid) => request(`/api/moments/${mid}/comments/${cid}`, {
  method: 'DELETE',
  params: { officialId: oid },
});

export const deleteMoment = (mid, oid) => request(`/api/moments/${mid}`, {
  method: 'DELETE',
  data: {
    officialId: oid,
  },
});

export const deleteTimingMoment = id => request(`/api/moments/timing/${id}`, { method: 'DELETE' });

export const getComments = async (mid, oid, p) => request(`/api/moments/${mid}/comments`, {
  method: 'GET',
  params: { oid, p },
});

export const getMoments = async (page, type, id, oId) => {
  const query = { officialId: oId };
  if (page && Number.isInteger(page) && page > 0) {
    query.p = page;
  }
  if (isNaN(type) === false && Number.isInteger(type)) {
    query.reqType = type;
    if (type !== 1) {
      query.reqId = parseInt(id, 10);
    }
  }
  return request('/api/moments', {
    method: 'GET',
    params: query,
  });
};

export const getTimingMoments = page => request('/api/moments/timing', {
  method: 'GET',
  params: { p: page },
});

export const postComment = (mid, data) => {
  const { isPrivate, ...rest } = data;
  return request(`/api/moments/${mid}/comments`, {
    method: 'POST',
    data: { ...rest, private: isPrivate },
  });
};

export const postMessage = (type, from, to, content) => request('/api/messages', {
  method: 'POST',
  data: {
    officialId: from,
    targetId: to,
    msgType: type,
    content,
  },
});

export const setMomentHot = (mid, oid, val) => request(`/api/moments/${mid}`, {
  method: 'PATCH',
  data: {
    action: 'setHot',
    officialId: oid,
    hot: val,
  },
});

export const setTopic = (mid, tid) => request(`/api/moments/${mid}`, {
  method: 'PATCH',
  data: {
    action: 'setTopic',
    topicId: tid,
  },
});

export const toggleLikeMoment = (mid, oid, type) => request(`/api/moments/${mid}`, {
  method: 'PATCH',
  data: {
    action: type,
    officialId: oid,
  },
});
