<template>
  <ALayout
    id="app"
    class="gzone-layout"
    :has-sider="true"
  >
    <ALayoutSider
      v-model="collapsed"
      :trigger="null"
      collapsible
    >
      <Logo />
      <SiderMenu class="gzone-menu" />
      <ALayoutFooter class="gzone-footer">
        &copy; {{ copyYear }} 光子工作室群
      </ALayoutFooter>
    </ALayoutSider>
    <ALayout>
      <ALayoutHeader class="gzone-header">
        <ARow
          type="flex"
          justify="space-between"
        >
          <ACol>
            <AIcon
              class="gzone-trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="switchSider"
            />
          </ACol>
          <ACol>
            <EnvBar />
            <UserBar />
          </ACol>
        </ARow>
      </ALayoutHeader>
      <ALayoutContent class="gzone-content">
        <RouterView />
      </ALayoutContent>
    </ALayout>
  </ALayout>
</template>

<script>
import Logo from '@/components/Logo.vue';
import SiderMenu from '@/components/SiderMenu.vue';
import UserBar from '@/components/UserBar.vue';
import EnvBar from '@/components/EnvBar.vue';

export default {
  name: 'Default',
  components: {
    Logo,
    SiderMenu,
    UserBar,
    EnvBar,
  },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    triggerClass() {
      return this.collapsed ? 'menu-unfold' : 'menu-fold';
    },
    copyYear() {
      const startYear = 2021;
      const currentYear = (new Date()).getFullYear();
      let year = `${startYear}`;
      if (currentYear > startYear) {
        year = `${startYear} - ${currentYear}`;
      }
      return year;
    },
  },
  methods: {
    switchSider() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
<style>
html,body {height: auto}
.ant-layout.gzone-layout {
  min-height: 100vh;
  height: 100%;
}
.gzone-layout .gzone-header {
  background: #fff;
  padding: 0;
}
.gzone-layout .gzone-trigger {
  font-size: 1.125rem;
  line-height: 4rem;
  padding: 0 1rem;
  cursor: pointer;
  transition: color 0.3s;
}
.gzone-layout .gzone-trigger:hover {
  color: #1890ff;
}
.gzone-layout .gzone-menu {
  position: relative;
  z-index: 2;
}
.gzone-layout .gzone-content {
  margin: 1rem 1rem 0;
  background: #fff;
  min-height: 40rem;
  height: auto;
  overflow: visible;
}
.gzone-layout .ant-page-header {
  padding: 0.5rem 1rem;
}
.gzone-layout .gzone-footer {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  padding: 1.25rem 0.8rem 1rem;
  font-size: 0.75rem;
}
</style>
