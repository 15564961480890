export function base64Encode(str) {
  let source = str;
  if (typeof str === 'object') {
    source = JSON.stringify(str);
  }
  return btoa(unescape(encodeURIComponent(source)));
}

export function base64Decode(str) {
  return decodeURIComponent(escape(atob(str)));
}


