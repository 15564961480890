<template>
  <div class="env-bar">
    <strong v-if="currentEnv != null">{{ currentEnv.name }}</strong>
    <RouterLink
      v-if="showLink"
      :to="{ name: 'ChangeEnv' }"
    >
      切换
    </RouterLink>
  </div>
</template>

<script>
import { getEnv, getEnvs } from '@/api';

export default {
  name: 'EnvBar',
  data() {
    return {
      envs: [],
      env: '',
    };
  },
  computed: {
    currentEnv() {
      return this.envs[this.env] || null;
    },
    showLink() {
      return this.$route.name !== 'ChangeEnv';
    },
  },
  watch: {
    $route() {
      this.setEnvs();
    },
  },
  created() {
    this.setEnvs();
  },
  methods: {
    async setEnvs() {
      try {
        const { envs } = await getEnvs();
        this.envs = envs;
        this.env = await getEnv();
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
<style>
.env-bar {
  display: inline-block;
  margin-right: 2rem;
}
</style>
