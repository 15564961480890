<template>
  <AResult title="你的账号尚未绑定系统帐户">
    <template #extra>
      <AFormModel
        ref="regForm"
        class="reg-form"
        :model="form"
        :rules="rules"
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <AFormModelItem
          label="绑定授权码"
          prop="authCode"
        >
          <AInput
            v-model="form.authCode"
            placeholder="请输入管理员提供的绑定授权码"
          />
        </AFormModelItem>
        <AFormItem
          :wrapper-col="{ span:16, offset: 6}"
        >
          <AButton
            type="primary"
            size="large"
            @click="onSubmit"
          >
            绑定帐号
          </AButton>
        </AFormItem>
      </AFormModel>
    </template>
  </AResult>
</template>
<script>
import { bindAccount } from '@/api';
export default {
  name: 'Register',
  props: {
    token: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      form: {
        openid: this.token,
        authCode: '',
      },
      rules: {
        authCode: [{ required: true, message: '授权码必须填写' }],
      },
    };
  },
  methods: {
    async bindAccount() {
      try {
        const { user } = await bindAccount(this.form);
        this.$success({
          title: '绑定成功',
          content: `您的QQ号已经与用户 ${user.name} 绑定\n`,
          onOk: () => {
            if (user.status === 2) {
              this.$router.push('/admin');
            } else {
              this.$router.replace({
                name: 'Error',
                query: { code: 40010 },
              });
            }
          },
        });
      } catch (err) {
        this.$error({
          title: '出错了',
          content: err.message,
        });
      }
    },
    onSubmit() {
      const vue = this;
      this.$refs.regForm.validate((valid) => {
        if (valid) {
          vue.bindAccount();
        }
      });
    },
  },
};
</script>
<style>
.reg-form .ant-form-item {
  text-align: left;
}
</style>
