import request from '@/api/request.js';

/**
 * 查询可用的头衔列表
 * @returns promise
 */
export const titleList = async () => request('/api/titles');

export const listTitles = async (page) => {
  const query = {};
  if (page && Number.isInteger(page) && page > 0) {
    query.p = page;
  }
  return request('/api/list-titles', {
    method: 'GET',
    params: query,
  });
};

export const queryTitles = async id => request(`/api/users/${id}/titles`);

export const setUserTitle = async data => request(`/api/users/${data.roleId}/title`, {
  method: 'POST',
  data,
});
