<template>
  <AResult
    title="请选择要管理的环境"
  >
    <AIcon
      slot="icon"
      type="environment"
    />
    <div slot="extra">
      <AFormItem>
        <ARadioGroup
          v-model="env"
          class="env-radio-group"
        >
          <ARadio
            v-for="(val, key) in envs"
            :key="key"
            class="env-radio"
            :value="key"
          >
            <strong>{{ val.name }}</strong>
          </ARadio>
        </ARadioGroup>
      </AFormItem>
      <AFormItem>
        <AButton
          type="primary"
          :loading="btnLoading"
          @click="onChangeEnv"
        >
          确定
        </AButton>
      </AFormItem>
    </div>
  </AResult>
</template>
<script>
import { getEnv, getEnvs, changeEnv, loginUser } from '@/api';
export default ({
  name: 'ChangeEnv',
  data() {
    return {
      envs: [],
      info: '',
      env: '',
      btnLoading: false,
    };
  },
  async created() {
    try {
      const { envs } = await getEnvs(true);
      this.envs = envs;
      this.env = getEnv();
    } catch (err) {
      this.$error({
        title: '出错了',
        content: `加载环境出错：${err.message}`,
      });
    }
  },
  methods: {
    async onChangeEnv() {
      this.btnLoading = true;
      if (this.env === '' || Object.keys(this.envs).includes(this.env) === false) {
        this.$error({
          title: '出错了',
          content: '必须选定要管理的区服',
        });
      } else {
        try {
          const { user, token, expire } = await changeEnv(this.env);
          loginUser(user, token, expire);
          localStorage.removeItem('actOfficialId');
          this.$success({
            title: '操作成功',
            content: '已经设置管理区服，请进行后续操作',
            onOk: () => {
              this.$router.push({ name: 'Home' });
            },
          });
        } catch (err) {
          this.$error({
            title: '出错了',
            content: err.message,
          });
        }
      }
      this.btnLoading = false;
    },
  },
});
</script>
<style>
.env-radio-group .env-radio {
  display:block;
  margin:0;
  text-align: left;
}
</style>
