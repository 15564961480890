<template>
  <AMenu
    ref="amenu"
    theme="dark"
    mode="inline"
    :default-selected-keys="actived"
    :default-open-keys="openKeys"
    :selected-keys="actived"
    @click="onChange"
  >
    <template v-for="menu in filterMenu(menus)">
      <AMenuItem
        v-if="!menu.children && menu.meta && menu.meta.title"
        :key="menu.path"
      >
        <AIcon
          v-if="menu.meta && menu.meta.icon"
          :type="menu.meta.icon"
        />
        <span>{{ menu.meta.title }}</span>
      </AMenuItem>
      <ASubMenu
        v-else
        :key="menu.path"
        :data-key="menu.path"
      >
        <AIcon
          v-if="menu.meta.icon"
          slot="title"
          :type="menu.meta.icon"
        />
        <span slot="title">{{ menu.meta.title }}</span>
        <AMenuItem
          v-for="submenu in filterMenu(menu.children)"
          :key="submenu.path"
        >
          <AIcon
            v-if="submenu.meta && submenu.meta.icon"
            :type="submenu.meta.icon"
          />
          <span>{{ submenu.meta.title }}</span>
        </AMenuItem>
      </ASubMenu>
    </template>
  </AMenu>
</template>
<script>
import { adminMenus } from '@/router/admin';

export default {
  name: 'SiderMenu',
  props: {
    menus: {
      type: Array,
      default: () => adminMenus,
    },
  },
  data() {
    return {
      actived: [],
      openKeys: [],
    };
  },
  watch: {
    $route(to) {
      const key = to.meta?.key || to.fullPath;
      this.actived = [key];
    },
  },
  created() {
    this.initMenu();
  },
  methods: {
    onChange({ key }) {
      if (key !== this.$route.fullPath) {
        this.$router.push(key);
      }
    },
    filterMenu: menus => menus.filter(menu => menu.meta && menu.meta.title),
    initMenu() {
      const { fullPath } = this.$route;
      if (fullPath.startsWith('/admin')) {
        const okey = this.$route.meta?.key || fullPath;
        this.actived = [okey];
        const openKey = /(?<openKey>\/admin\/[^/]+)/.exec(fullPath);
        if (openKey?.groups) {
          this.openKeys = [openKey.groups.openKey];
        }
      }
    },
  },
};
</script>
