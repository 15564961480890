import { base64Decode, base64Encode } from '@/helper.js';
import request from './request';

export const USER_APPROVE = 'approve';
export const USER_DEREGISTER = 'deregister';
export const LOCAL_ENVS_KEY = 'gzone_envs';
export const OFFICIAL_ID_KEY = 'actOfficialId';
export const getGlobalConfig = async () => request('/api/configs');
export const auditInfo = async (infoId, approved, type) => request(`/api/audits/${infoId}`, {
  method: 'PATCH',
  data: {
    auditType: type,
    approved,
  },
});

export const delUserData = async (roleId, start, end) => request('/api/userdata', {
  method: 'DELETE',
  data: {
    roleid: roleId,
    startTime: start,
    endTime: end,
  },
});

export const getAudits = async (type, p) => request('/api/audits', {
  method: 'GET',
  params: { type, p },
});

export const getLogs = async p => request('/api/logs', {
  method: 'GET',
  params: { p },
});

export const getEnvs = async (force) => {
  let envs = null;
  if (!force) {
    const encrypedData = localStorage.getItem(LOCAL_ENVS_KEY);
    if (encrypedData) {
      const localEnvs = JSON.parse(base64Decode(encrypedData));
      if (localEnvs && typeof localEnvs === 'object') {
        envs = Promise.resolve({ envs: localEnvs });
      }
    }
  }
  if (force || envs === null) {
    envs = request('/api/envs');
  }
  envs.then(({ envs }) => {
    putEnvs(envs);
  });
  return envs;
};

export const getCachedEnvs = () => {
  const encrypedData = localStorage.getItem(LOCAL_ENVS_KEY);
  if (encrypedData) {
    const localEnvs = JSON.parse(base64Decode(encrypedData));
    if (localEnvs && typeof localEnvs === 'object') {
      return localEnvs;
    }
  }
  return null;
};

export const putEnvs = (envs) => {
  const data = base64Encode(JSON.stringify(envs, null, 0));
  localStorage.setItem(LOCAL_ENVS_KEY, data);
};

export const changeEnv = async env => request('/api/users/env', {
  method: 'PATCH',
  data: { action: 'changeEnv', env },
});

export const getEnvByKey = (key) => {
  const envs = getCachedEnvs();
  if (envs) {
    return envs[key];
  }
  return null;
};

export * from './auth';
export * from './moment';
export * from './official';
export * from './topic';
export * from './title';
