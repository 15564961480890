<template>
  <div class="user-bar">
    <AAvatar
      v-if="user.name"
      class="user-face"
      :src="user.avatar"
      icon="user"
      size="large"
    />
    <div class="user-info">
      <strong
        v-if="user.name"
        class="line"
      >{{ user.name }}</strong>
      <span
        v-if="user.role"
        class="line"
      >{{ user.role.title }} - {{ status }}</span>
    </div>
    <AButton
      type="danger"
      size="small"
      @click="logout"
    >
      退出
    </AButton>
  </div>
</template>

<script>
import { getUser, logoutUser } from '@/api';

export default {
  name: 'UserBar',
  data() {
    return {
      user: {
        name: '',
        avatar: '',
        status: 1,
      },
    };
  },
  computed: {
    status() {
      return this.user.status === 2 ? '已授权' : '待审核';
    },
  },
  async created() {
    const user = await getUser();
    this.user = user;
  },
  methods: {
    logout() {
      logoutUser();
      window.location.reload();
    },
  },
};
</script>
<style>
.user-bar {
  padding-right: 2rem;
  display: inline-block;
}
.user-bar .user-info {
  display: inline-block;
  line-height: 1.5rem;
  vertical-align: middle;
  margin: 0 1rem;
  text-align: center;
}
.user-bar .line {
  display: block;
}
</style>
